import React, { useContext, useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import BottomBanner from "../components/bottom-banner";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Helmet } from "react-helmet";

const BlogPostTemplate = ({ data: { post, site } }) => {
	const pluginImage = getImage(
		post.articleFields.featureImage.localFile.childImageSharp.gatsbyImageData
	);
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "News",
				item: {
					url: `${siteUrl}/news`,
					id: `${siteUrl}/news`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/news/${post.slug}`,
					id: `${siteUrl}/news/${post.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${post.title} | Eastbourne Carnival`}
				description={parse(post.excerpt)}
				openGraph={{
					url: `${siteUrl}/news/${post.slug}`,
					title: `${post.title} | Eastbourne Carnival`,
					description: parse(post.excerpt),
					type: "article",
					images: [
						{
							url: `${post.articleFields.featureImage.localFile.publicURL}`,
							width: `${post.articleFields.featureImage.localFile.childImageSharp.original.width}`,
							height: `${post.articleFields.featureImage.localFile.childImageSharp.original.height}`,
							alt: `${post.articleFields.featureImage.altText}`,
						},
					],
				}}
			/>
			<div className="bg-secondary pt-5 pt-lg-7 pb-10">
				<Container>
					<Row>
						<Col className="text-center">
							{" "}
							<h1 className="text-white carnival-normal display-4">
								{post.title}
							</h1>
							<p className="text-primary jost-normal">{post.dateGmt}</p>
						</Col>
					</Row>
				</Container>
			</div>
			<Container>
				<Row className="justify-content-center">
					<Col lg={10} xl={8}>
						<div
							style={{
								top: "-6rem",
							}}
							className="position-relative"
						>
							<GatsbyImage
								className=" w-100 "
								image={
									post.articleFields.featureImage.localFile.childImageSharp
										.gatsbyImageData
								}
								alt={post.articleFields.featureImage.altText}
							/>
						</div>
					</Col>
				</Row>
			</Container>

			<Container className=" pb-md-10 pb-5">
				<Row className="justify-content-center">
					<Col
						style={{
							top: "-5rem",
						}}
						lg={10}
						xl={8}
					>
						<div className="article-content">{parse(post.content)}</div>
					</Col>
				</Row>
			</Container>
			<BottomBanner
				mainBg="bg-secondary"
				position="top--20"
				content={
					<Row>
						<Col className="py-5 pb-md-0 mt-md-5 pt-md-10">
							<h2 className="text-white text-center  fs-1 shortstack">
								FOLLOW US ON SOCIAL MEDIA
							</h2>
							<div className="d-flex pt-4 justify-content-center align-items-center">
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.facebook.com/eastbournecarnival"
								>
									<FaFacebookF className="text-white display-2 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://twitter.com/EBCarnival"
								>
									<FaTwitter className="text-white display-2 ms-5 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.instagram.com/eastbournecarnival/"
								>
									<FaInstagram className="text-white display-2 ms-5 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.youtube.com/@eastbournecarnival990"
								>
									<FaYoutube className="text-white display-2 ms-5 link-white" />
								</a>
							</div>
						</Col>
					</Row>
				}
			/>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query BlogPostById($id: String!) {
		post: wpArticle(id: { eq: $id }) {
			id
			excerpt
			content
			title
			dateGmt(formatString: "MMMM DD, YYYY")
			articleFields {
				featureImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
